/* eslint-disable @typescript-eslint/no-explicit-any */
type SortByIteratee<T> = (item: T) => any;

const isValueFalsey = (val: any) => !val;

const formatValueForComparision = <T>(val: string | T): string | T =>
  typeof val === 'string' ? val.toLowerCase() : val;

const getValuesForComparision = <T>(itemA: T, itemB: T, prop: keyof T | SortByIteratee<T>): any[] =>
  [itemA, itemB].map((item) =>
    formatValueForComparision(typeof prop === 'function' ? prop(item) : item[prop])
  );
/* eslint-enable @typescript-eslint/no-explicit-any */

export function sortBy<T>(collection: T[], iteratee: keyof T | SortByIteratee<T>): T[] {
  return collection.sort((itemA, itemB) => {
    const [valA, valB] = getValuesForComparision(itemA, itemB, iteratee);

    if (valA === valB || (isValueFalsey(valA) && isValueFalsey(valB))) {
      return 0;
    }

    if (isValueFalsey(valA)) return 1;
    if (isValueFalsey(valB)) return -1;

    if (typeof valA === 'string' && typeof valB === 'string') {
      return valA.localeCompare(valB, 'en-US', { sensitivity: 'base', numeric: true });
    }

    return valA < valB ? 1 : -1;
  });
}
